import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { useLocation } from '@reach/router'

const StyledContainer = styled(Container)`
  //  No media query for extra small devices (portrait phones, less than 576px)
  background-color: #cbd6db;
  a {
    color: #fc5400;
    &:hover {
      color: #fc8200;
    }
  }
  div.col.footer {
    padding: 0 2rem 2rem 2rem;
    /* should be var as same as container */
    background-color: #fbe0c3;
    color: #b53d01;
  }
  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  }
  @media print {
    /* All your print styles go here */
    background-color: white !important;
    div.col.footer {
      display: none !important;
    }
  }
`
const ContentCol = styled(Col)`
  //  No media query for extra small devices (portrait phones, less than 576px)
  padding: 0 !important;
  div.bg-light.col {
    background-color: #fbe0c3 !important;
    color: #a86319;
    div.row.section-2 {
      padding: 0 1rem 0 1rem !important;
    }
  }
  div.bg-secondary.row {
    background-color: #ffbb98 !important;
    margin-top: 2rem;
    border-radius: 50px;
    color: #b53d01 !important;
    h2 {
      margin: 1.2rem 0 2rem 0;
      font-size: 2.2rem;
    }
  }

  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
    div.bg-light.col {
      div.row.section-2 {
        padding: 0 3rem 0 3rem !important;
      }
    }
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  }

  @media print {
    /* All your print styles go here */
    p {
      page-break-inside: avoid;
    }
    p,
    li {
      font-size: 1rem !important;
    }
    h1 {
      font-size: 1.5rem !important;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.2rem !important;
    }
    p.fs-3{
      font-size: 1.4rem !important;
    } 
    span.fs-2 {
      font-size: 1.3rem !important;
      page-break-after: avoid !important;
    }
    div.bg-secondary.row {
      background-color: white !important;
    }
  }
`

function LayoutAnleitungen({ children }) {
    const { pathname } = useLocation()
    console.log('Anleitungen pathnameeeee', pathname)

  return (
    <StyledContainer fluid>
      <main>
        <Row>
          {pathname !== '/anleitungen/' ? <Col lg={2}></Col> : null}
          <ContentCol>{children}</ContentCol>
          {pathname !== '/anleitungen/' ? <Col lg={2}></Col> : null}
        </Row>
      </main>
      <Row id="footer">
        <Col lg={2}></Col>
        <Col className="footer">
          <Row>
            <Col sm={12} md={6}>
              <p className="copyright">
                &copy; {new Date().getFullYear()} Angelo Klap
              </p>
            </Col>
            <Col sm={12} md={6}>
              <p>
                Webentwicklung von
                <a
                  href="https://www.klap-web.ch/"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.klap-web.ch
                </a>
              </p>
            </Col>
            <Col sm={12}>
              <p>
                Kontakt:
                <a
                  href="mailto:info@klap-web.ch"
                  target="_blank"
                  rel="noreferrer"
                >
                  info@klap-web.ch
                </a>
              </p>
            </Col>
          </Row>
        </Col>
        <Col lg={2}></Col>
      </Row>
    </StyledContainer>
  )
}

export default LayoutAnleitungen
